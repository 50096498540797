<template>
  <nav>
    <b-navbar :toggleable="true" class="small py-0 navbar-expand-sm" type="dark" variant="top">
      <b-container>
        <b-collapse is-nav id="nav_collapse">
          <b-navbar-nav class="ml-auto">
            <!--b-nav-form>
              <b-form-input size="sm" class="mr-sm-2" type="text" placeholder="Search"/>
              <b-button size="sm" class="my-2 my-sm-0" type="submit">Search</b-button>
            </b-nav-form-->

            <!--b-nav-item-dropdown text="Lang" right>
              <b-dropdown-item href="#">EN</b-dropdown-item>
              <b-dropdown-item href="#">ES</b-dropdown-item>
              <b-dropdown-item href="#">RU</b-dropdown-item>
              <b-dropdown-item href="#">FA</b-dropdown-item>
            </b-nav-item-dropdown-->

            <b-nav-item-dropdown right>
              <!-- Using button-content slot -->
              <template slot="button-content">
                <span v-if="loggedInUser">{{ loggedInUser.name }}</span>
              </template>
              <b-dropdown-item href="#" @click.prevent="logout()">Útskrá</b-dropdown-item>
            </b-nav-item-dropdown>
          </b-navbar-nav>
        </b-collapse>
      </b-container>
    </b-navbar>

    <b-navbar toggleable="md" type="dark" variant="subtop">
      <b-container>
        <b-navbar-toggle target="nav_collapse"></b-navbar-toggle>

        <b-navbar-brand v-if="loggedInUser.is_master_admin === 1 || loggedInUser.is_mms === 1" :to="{ name: 'Index' }"
          ><img src="../assets/logo.png" style="height: 32px" /> FERILBÓK</b-navbar-brand
        >
        <b-navbar-brand v-else :to="{ name: 'Index' }"
          ><img src="../assets/logo.png" style="height: 32px" /> FERILBÓK RAUNFÆRNIMAT</b-navbar-brand
        >

        <b-collapse is-nav id="nav_collapse">
          <b-navbar-nav class="ml-auto">
            <b-nav-item v-if="loggedInUser.can_read === 1" :exact="true" :to="{ name: 'UserList' }">Notendur</b-nav-item>
            <!-- ADA: símmenntunviðbót : leyfa master admin að sjá -->
            <b-nav-item
              v-if="loggedInUser.is_master_admin === 1 || loggedInUser.is_admin === 1"
              :exact="true"
              :to="{ name: 'InstitutionList' }"
            >
              Stofnanir
            </b-nav-item>
            <b-nav-item
              v-if="loggedInUser.is_master_admin === 1 || loggedInUser.is_admin === 1 || userFullAccess()"
              :exact="true"
              :to="{ name: 'ContractList' }"
            >
              Samningar
            </b-nav-item>
            <b-nav-item
              v-if="loggedInUser.is_master_admin === 1 || loggedInUser.is_admin === 1"
              :exact="true"
              :to="{ name: 'RegulationList' }"
            >
              Upplýsingar
            </b-nav-item>
            <b-nav-item v-if="loggedInUser.can_read === 1" :exact="true" :to="{ name: 'ProfessionList' }">Starfsgreinar</b-nav-item>
            <b-nav-item v-if="loggedInUser.can_read === 1" :exact="true" :to="{ name: 'VersionList' }">Útgáfur</b-nav-item>
            <b-nav-item
              v-if="loggedInUser.is_master_admin === 1 || loggedInUser.is_admin === 1"
              :exact="true"
              :to="{ name: 'WorkplaceList' }"
            >
              Vinnustaðir
            </b-nav-item>
            <!-- <b-nav-item
              v-if="loggedInUser.is_master_admin === 1 || loggedInUser.is_admin === 1 || userFullAccess()"
              :exact="true"
              :to="{ name: 'CareerbookApplicationList' }"
            >
              Ferilbækur umsækjanda
            </b-nav-item> -->
            <b-nav-item v-if="loggedInUser.can_read === 1" :exact="true" :to="{ name: 'CareerbookList' }">Ferilbækur</b-nav-item>
            <b-nav-item v-if="loggedInUser.can_view_statistics === 1" :exact="true" :to="{ name: 'StatisticsList' }"> Tölfræði </b-nav-item>
          </b-navbar-nav>
        </b-collapse>
      </b-container>
    </b-navbar>
    <div v-for="error in errorList" :key="error.text" class="systemErrorMessage">
      {{ error.text }}
    </div>
    <div v-for="warning in warningList" :key="warning.text" class="systemWarningMessage">
      {{ warning.text }}
    </div>
    <div v-for="info in infoList" :key="info.text" class="systemInfoMessage">
      {{ info.text }}
    </div>
    <br />
  </nav>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import auth from '@/api/auth';

export default {
  name: 'navbar',
  computed: {
    ...mapState({
      loggedInUser: 'loggedInUser',
    }),
  },
  data() {
    return {
      errorList: [],
      warningList: [],
      infoList: [],
    };
  },
  methods: {
    logout() {
      auth.removeToken();
      this.doClean();
      this.$router.push({ name: 'Login', query: { logout: 'true' } });
    },
    async getSystemErrorStatus() {
      this.loading = true;
      try {
        const response = await auth.showSystemStatusLoggedIn('error');
        this.errorList = response.data.items;
      } catch (e) {
        this.$log.error(e);
      } finally {
        this.loading = false;
      }
    },
    userFullAccess() {
      if (
        this.loggedInUser.can_read === 1 &&
        this.loggedInUser.can_create_book === 1 &&
        this.loggedInUser.can_create_users === 1 &&
        this.loggedInUser.can_edit === 1 &&
        this.loggedInUser.can_update_book === 1 &&
        this.loggedInUser.is_mms === 1
      ) {
        return true;
      }
      return false;
    },

    async getSystemWarningStatus() {
      this.loading = true;
      try {
        const response = await auth.showSystemStatusLoggedIn('warning');
        this.warningList = response.data.items;
      } catch (e) {
        this.$log.error(e);
      } finally {
        this.loading = false;
      }
    },

    async getSystemInfoStatus() {
      this.loading = true;
      try {
        const response = await auth.showSystemStatusLoggedIn('info');
        this.infoList = response.data.items;
      } catch (e) {
        this.$log.error(e);
      } finally {
        this.loading = false;
      }
    },
    ...mapActions(['doClean']),
  },
  created() {
    this.getSystemErrorStatus();
    this.getSystemWarningStatus();
    this.getSystemInfoStatus();
  },
};
</script>
<style lang="scss" scoped>
.systemErrorMessage {
  padding: 10px;
  font-weight: bold;
  text-align: center;
  background-color: rgb(247, 95, 95);
}

.systemWarningMessage {
  padding: 10px;
  font-weight: bold;
  text-align: center;
  background-color: rgb(255, 255, 128);
}

.systemInfoMessage {
  padding: 10px;
  font-weight: bold;
  text-align: center;
  background-color: #acd4ff;
}
</style>
